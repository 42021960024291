import { ResponsiveText } from '@eventbrite/wagtail-components';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import { BaseModuleProps } from '../../modules/types';
import LazyloadImage from '../LazyloadImage';
import './featureBlock.scss';

export interface IconBlock {
    imageUrl: string;
    altText: string;
}

export interface FeatureBlockInterface {
    icon?: IconBlock;
    title?: string;
    description?: string;
    style?: any;
}

type FeatureBlockProps = FeatureBlockInterface & BaseModuleProps;

const FeatureBlock = ({
    icon,
    title,
    description,
    style,
    variant,
}: FeatureBlockProps) => {
    const baseOptions = { variant };
    const theme = useTheme();
    const [textClass] = theme.generateStyles('text', baseOptions);
    const moduleOverrideClassByTheme = `feature-block__${theme.theme}`;

    return (
        <div
            className={`feature-block ${moduleOverrideClassByTheme}`}
            style={style || {}}
        >
            {icon && (
                <LazyloadImage
                    classes="feature-block__image-container"
                    image={icon.imageUrl}
                    alt={icon.altText}
                    style={{}}
                />
            )}

            <div className={`feature-block__content ${textClass}`}>
                {title && (
                    <ResponsiveText
                        base={{ fontSize: 20, lineHeight: 28 }}
                        small={{ fontSize: 16, lineHeight: 24 }}
                        className="feature-block__content__title"
                    >
                        <span
                            //eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: title }}
                        ></span>
                    </ResponsiveText>
                )}
                {description && (
                    <div
                        className="feature-block__content__description"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                )}
            </div>
        </div>
    );
};

export default FeatureBlock;
